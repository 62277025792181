<template>
  <app-module-view>
    <template slot="body">
      <app-preloader-full v-if="callingAPI"></app-preloader-full>
      <app-historical-performance-filter></app-historical-performance-filter>
      <app-historical-performance-cluster-chart v-if="isLoaded"></app-historical-performance-cluster-chart>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import HistoricalPerformanceFilter from './HistoricalPerformanceFilter'
import HistoricalPerformanceClusterChart from './components/HistoricalPerformanceClusterChart'
import { mapState } from 'vuex'
import PreloaderFull from '../preloader/PreloaderFull'

export default {
  name: 'HistoricalPerformance',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isLoaded () {
      return this.$store.getters['historicalPerformance/loaded']
    },
    filter () {
      return this.$store.getters['historicalPerformance/filter']
    }
  },
  created () {
    this.$store.commit('editorialPerformance/setLoadedDepartment', false)
  },
  components: {
    appModuleView: ModuleView,
    appHistoricalPerformanceFilter: HistoricalPerformanceFilter,
    appHistoricalPerformanceClusterChart: HistoricalPerformanceClusterChart,
    appPreloaderFull: PreloaderFull
  }
}
</script>
