<template>
  <div>
    <div class="card" v-if="isLoaded">
      <div class="card-body">
        <button
          v-for="metric in metricsFilter" :key="`filter_${metric.id}`"
          class="btn btn-secondary m-r-5"
          :class="{active: metric.visible}"
          @click.passive="toggleMetricsFilter(metric.id, $event)"
        >
          {{ $t('kpi.historicalPerformanceButton.' + metric.id) }}
        </button>
      </div>
    </div>
    <template v-if="isLoaded">
      <template v-for="(metric, index) in metricsFilter">
        <div v-if="metric.visible" class="card" :key="`item-${index}`">
          <div class="card-body">
            <div class="col-lg-12">
              <h2>{{ getMetricTitle(metric.id) }}</h2>
              <div style="height: 670px;">
                <app-line-chart
                  :chart-data="getChartData(metric.id)"
                  :height="670"
                  :hide-zero-values-tooltip="true"
                  :max-labels="24"
                >
                </app-line-chart>
              </div>
              <div class="source">{{ $t('kpi.source') }}</div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ChartMixin from '@/components/mixins/ChartMixin'
import LineChart from '@/components/chart/LineChart'
import { AVAILABLE_HISTORICAL_METRICS } from '@/model/ValueObject/HistoricalPerformanceMetrics'
import { CLUSTER_VIEW, DEPARTMENT_VIEW } from '@/model/ValueObject/HistoricalViewOptions'
import { TIMEFRAME_WEEK } from '@/model/ValueObject/HistoricalViewTimeframes'
import moment from 'moment'

export default {
  name: 'HistoricalPerformanceClusterChart',
  mixins: [ChartMixin],
  data () {
    return {
      loading: false,
      historicalPerformanceMetrics: AVAILABLE_HISTORICAL_METRICS,
      performanceChartData: {
        labels: [],
        datasets: []
      },
      metricsFilter: AVAILABLE_HISTORICAL_METRICS.map(metric => {
        return {
          id: metric.id,
          visible: true
        }
      }),
      colors: []
    }
  },
  methods: {
    getChartData (metric) {
      return {
        labels: this.getLabels(),
        datasets: this.getDataSets(metric)
      }
    },
    getDataSets (type) {
      let dataSets = []
      this.performanceData.forEach(historyUnit => {
        dataSets.push(
          {
            label: this.getLabelName(historyUnit),
            borderColor: this.getColor(historyUnit),
            backgroundColor: this.getColor(historyUnit),
            fill: false,
            data: historyUnit.performanceHistory.map(value => {
              return Math.round((value[type] + Number.EPSILON) * 100) / 100
            })
          }
        )
      })
      if (this.filter.filterType === DEPARTMENT_VIEW) {
        dataSets = this.addAveragesChartToDatasets(dataSets)
      }
      return dataSets
    },
    getLabelName (historyUnit) {
      let label = this.$t('kpi.notAvailable')
      if (this.filter.filterType === CLUSTER_VIEW) {
        const department = this.$store.getters['department/getById'](historyUnit.departmentId)
        if (department) {
          label = department.name
        }
      } else {
        const user = this.$store.getters['user/userById'](historyUnit.userId)
        if (user) {
          label = user.fullName
        }
      }
      return label
    },
    getLabels () {
      if (this.performanceData.length > 0) {
        return this.performanceData[0].performanceHistory.map(history => {
          const date = moment(history.startDate)
          if (this.filter.timeGroupType === TIMEFRAME_WEEK) {
            return date.format('W') + this.$t('kpi.weekShort') + ' ' + date.format('Y')
          }
          return date.format('M') + '/' + date.format('Y')
        })
      }
    },
    getColor (historyUnit) {
      let id = historyUnit.departmentId
      if (this.filter.filterType === DEPARTMENT_VIEW) {
        id = historyUnit.userId
      }
      let color = this.colors.find(color => {
        return color.id === parseInt(id)
      })
      if (color) {
        return color.value
      }
      color = {
        id: id,
        value: this.getRandomColor()
      }
      this.colors.push(color)
      return color.value
    },
    getRandomColor () {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    },
    getMetricTitle (id) {
      if (this.filter.filterType === CLUSTER_VIEW) {
        return this.$t('kpi.clusterViewMetricsDescription.' + id)
      } else {
        return this.$t('kpi.departmentViewMetricsDescription.' + id)
      }
    },
    addAveragesChartToDatasets (dataSets) {
      dataSets.push(
        {
          label: this.$t('kpi.average'),
          borderColor: '#000',
          backgroundColor: '#000',
          fill: false,
          data: this.calculateAveragesWithoutZero(dataSets)
        }
      )
      return dataSets
    },
    calculateAverages (dataSets) {
      const averages = []
      const usersCount = dataSets.length
      dataSets.forEach(user => {
        user.data.forEach(function (value, i) {
          if (!Object.prototype.hasOwnProperty.call(averages, i)) {
            averages[i] = 0
          }
          averages[i] = averages[i] + value
        })
      })
      averages.forEach(function (value, i) {
        averages[i] = Math.round(((averages[i] / usersCount) + Number.EPSILON) * 100) / 100
      })
      return averages
    },
    calculateAveragesWithoutZero (dataSets) {
      const averages = []
      const nonZeroUsersCount = []
      dataSets.forEach(user => {
        user.data.forEach(function (value, i) {
          if (!Object.prototype.hasOwnProperty.call(averages, i)) {
            averages[i] = 0
            nonZeroUsersCount[i] = 0
          }
          if (value > 0) {
            averages[i] = averages[i] + value
            nonZeroUsersCount[i]++
          }
        })
      })
      averages.forEach(function (value, i) {
        if (nonZeroUsersCount[i] > 0) {
          averages[i] = Math.round(((averages[i] / nonZeroUsersCount[i]) + Number.EPSILON) * 100) / 100
        }
      })
      return averages
    },
    toggleMetricsFilter (key, event) {
      event.target.blur()
      const index = this.metricsFilter.findIndex(metrics => metrics.id === key)
      this.metricsFilter[index].visible = !this.metricsFilter[index].visible
    }
  },
  computed: {
    performanceData () {
      return this.$store.getters['historicalPerformance/historyData']
    },
    filter () {
      return this.$store.getters['historicalPerformance/filter']
    },
    isLoaded () {
      return this.$store.getters['historicalPerformance/loaded']
    }
  },
  components: {
    appLineChart: LineChart
  }
}
</script>

<style lang="scss" scoped>
  .source {
    float: right;
    margin-top: 10px;
    font-size: 12px;
  }

  .card-body button {
    margin-top: 5px;
  }
</style>
