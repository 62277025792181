<template>
  <section>
    <div class="card">
      <div class="card-body">
        <label class="btn btn-secondary m-r-5" :class="{ 'active': filter.filterType === CLUSTER_VIEW }">
          <input type="radio" v-model="filter.filterType" :value="CLUSTER_VIEW"> {{ $t('kpi.cluster') }}
        </label>
        <label class="btn btn-secondary m-r-5" :class="{ 'active': filter.filterType === DEPARTMENT_VIEW }">
          <input type="radio" v-model="filter.filterType" :value="DEPARTMENT_VIEW"> {{ $t('kpi.department') }}
        </label>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterAll">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-select v-if="this.filter.filterType === CLUSTER_VIEW"
                          v-model="filter.cluster"
                          :options="clusters"
                          optionTitle="name"
                          :convertValue="false"
                          id="performanceFilter_cluster"
                          :label="$t('kpi.cluster')"
                          :tool-tip="$t('kpi.toolTip.clusterDesc')"
              >
              </app-select>
              <app-select v-if="this.filter.filterType === DEPARTMENT_VIEW"
                          v-model="filter.department"
                          :options="departments"
                          optionTitle="name"
                          :convertValue="false"
                          required
                          id="performanceFilter_department"
                          :label="$t('menu.departments')"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.timeGroupType"
                :options="timeGroupTypes"
                optionTitle="title"
                :convertValue="false"
                noEmptyValue
                id="performanceFilter_timeGroup"
                :label="$t('kpi.timeGroupType')"
              >
              </app-select>
            </div>
            <div class="col-lg-4 col-md-6">
              <app-datepicker
                type="date"
                mode="range"
                :show-months="2"
                v-model="filter.dateRange"
                id="filter_date_range"
                :label="$t('kpi.period')"
                :convertValue="false"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-1">
              <button
                v-if="filter.cluster || filter.department"
                class="btn btn-success pull-right m-t-30"
                @click.prevent="filterAll()"
              >{{ $t('beUser.filter.search') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Select from '../form/select/Select'
import HistoricalPerformanceFilter from '../../model/HistoricalPerformanceFilter'
import Datepicker from '../form/Datepicker'
import { CLUSTER_VIEW, DEPARTMENT_VIEW } from '../../model/ValueObject/HistoricalViewOptions'
import { TIMEFRAME_WEEK, TIMEFRAME_MONTH } from '../../model/ValueObject/HistoricalViewTimeframes'
import { DISABLED_DEPARTMENTS } from '../../model/ValueObject/DisabledDepartments'

export default {
  name: 'HistoricalPerformanceFilter',
  data () {
    return {
      CLUSTER_VIEW: CLUSTER_VIEW,
      DEPARTMENT_VIEW: DEPARTMENT_VIEW,
      filter: this._.cloneDeep(HistoricalPerformanceFilter),
      filterOutDepartments: DISABLED_DEPARTMENTS
    }
  },
  watch: {
    'filter.filterType': function (val) {
      if (val === CLUSTER_VIEW) {
        this.filter.department = ''
      } else {
        this.filter.cluster = ''
      }
    },
    'filter.dateRange': function (val) {
      const string = val.split(' ')
      if (string.length === 3) {
        this.filter.startDate = string[0]
        this.filter.endDate = string[string.length - 1]
      }
    }
  },
  components: {
    appSelect: Select,
    appDatepicker: Datepicker
  },
  computed: {
    departments () {
      return this.$store.getters['department/all'].filter(function (department) {
        return this.filterOutDepartments.indexOf(department.id) < 0
      }, this)
    },
    clusters () {
      return this.$store.getters['cluster/all']
    },
    timeGroupTypes () {
      return [
        {
          id: TIMEFRAME_WEEK,
          title: this.$t('kpi.weekly')
        },
        {
          id: TIMEFRAME_MONTH,
          title: this.$t('kpi.monthly')
        }
      ]
    }
  },
  methods: {
    getDepartments () {
      this.$store.dispatch('department/fetchAll')
    },
    getClusters () {
      this.$store.dispatch('cluster/fetchAll')
    },
    filterAll () {
      this.$store.commit('historicalPerformance/setFilter', this.filter)
      if (this.filter.filterType === DEPARTMENT_VIEW) {
        this.$store.dispatch('historicalPerformance/fetchDepartmentView')
      }
      if (this.filter.filterType === CLUSTER_VIEW) {
        this.$store.dispatch('historicalPerformance/fetchClusterView')
      }
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['historicalPerformance/filter']
  },
  mounted () {
    this.getDepartments()
    this.getClusters()
  }
}
</script>
